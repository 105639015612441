import { useContext, useMemo, useState, useReducer, useCallback, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { useCookies } from 'hooks/useCookies';
import { useGoogleTagManager } from 'hooks/useGoogleTagManager';
import { triggerReload } from 'helpers/triggerReload';
import { isAUNZ } from 'helpers/isAUNZ';

import { cookiePreferencesReducer } from './cookiePreferencesReducer';
import { CookiePreferencesContext } from './CookiePreferencesContext';
import { defaultPreferences } from './constants';
import type {
  CookieCategories,
  CookiePreferencesContextProps,
  CookiePreferencesProviderProps,
  CookieType,
} from './types';

export const CookiePreferencesProvider = ({ children }: CookiePreferencesProviderProps) => {
  const { removeAllCookies, removeConsentCookie } = useCookies();
  const { initializeGoogleTagManager } = useGoogleTagManager();

  const [gdprPreferencesAccepted, setGdprPreferencesAccepted] = useLocalStorage(
    'gdprPreferencesAccepted',
    false
  );
  const [cookieConsentPreferences, setCookieConsentPreferences] = useLocalStorage<CookieCategories>(
    'cookieConsent',
    defaultPreferences
  );

  // this controls re-initialising the gdpr preferences when users enter the site
  const [shouldInitialize, setShouldInitialize] = useState(true);
  // shows the banner for locales all locales except au/nz initially
  const [hasBanner, setHasBanner] = useLocalStorage('cookieBanner', false);
  // controls when we update local storage so it doesn't default optional flags to false on every page reload
  const [updateCookieConsentPreferences, setUpdateCookieConsentPreferences] = useState(false);
  // controls when we update consent cookie
  const [updateConsentCookie, setUpdateConsentCookie] = useState(false);
  // cookie preferences state
  const [cookiePreferences, cookiePreferencesDispatch] = useReducer(cookiePreferencesReducer, {
    essential: true,
    advertising: cookieConsentPreferences?.advertising ?? defaultPreferences.advertising,
    performance: cookieConsentPreferences?.performance ?? defaultPreferences.performance,
    functionality: cookieConsentPreferences?.functionality ?? defaultPreferences.functionality,
  });
  // this preferences banner is by default not shown until the user clicks on the manage preferences button
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  // this should pull first from local storage and check of the preferences exist
  const [isBannerOpen, setIsBannerOpen] = useState(hasBanner);

  const handleManagePreferenceClick = useCallback(() => {
    setIsPreferencesOpen(true);
    setIsBannerOpen(false);
  }, []);

  const handleOnRejectAll = useCallback(() => {
    cookiePreferencesDispatch({ type: 'reject-all' });
    setIsBannerOpen(false);
    setIsPreferencesOpen(false);
    setHasBanner(false);
    setGdprPreferencesAccepted(true);
    setUpdateCookieConsentPreferences(true);
    setUpdateConsentCookie(true);
    triggerReload();
  }, [setHasBanner, setGdprPreferencesAccepted]);

  const handleOnAcceptAll = useCallback(() => {
    cookiePreferencesDispatch({ type: 'accept-all' });
    setIsBannerOpen(false);
    setHasBanner(false);
    setGdprPreferencesAccepted(true);
    setUpdateCookieConsentPreferences(true);
    setUpdateConsentCookie(true);
  }, [setHasBanner, setGdprPreferencesAccepted]);

  const handlePreferenceClick = useCallback((cookieType: CookieType, value: boolean) => {
    cookiePreferencesDispatch({ type: 'individual-cookie', preference: { cookieType, value } });
  }, []);

  const handleOnAcceptChoice = useCallback(() => {
    setIsPreferencesOpen(false);
    setHasBanner(false);
    setGdprPreferencesAccepted(true);
    setUpdateCookieConsentPreferences(true);
    setUpdateConsentCookie(true);
  }, [setHasBanner, setGdprPreferencesAccepted]);

  const initialiseGDPR = useCallback(() => {
    setShouldInitialize(false);

    const preferences = gdprPreferencesAccepted ? cookiePreferences : defaultPreferences;

    // we initialise gtm/advertising cookies and gtag/performance cookies for au/nz locales
    initializeGoogleTagManager(preferences, isBannerOpen);

    if (isAUNZ && !gdprPreferencesAccepted) {
      setGdprPreferencesAccepted(false);
      cookiePreferencesDispatch({ type: 'accept-all' });
      setUpdateCookieConsentPreferences(true);
      setUpdateConsentCookie(true);
    }

    if (!isAUNZ && !gdprPreferencesAccepted) {
      removeAllCookies();
      setGdprPreferencesAccepted(false);
      cookiePreferencesDispatch({ type: 'reject-all' });
      setUpdateCookieConsentPreferences(true);
      setIsBannerOpen(true);
      removeConsentCookie();
    }
  }, [
    cookiePreferences,
    gdprPreferencesAccepted,
    isBannerOpen,
    removeConsentCookie,
    removeAllCookies,
    setGdprPreferencesAccepted,
    initializeGoogleTagManager,
  ]);

  const cookiePreferencesValue = useMemo<CookiePreferencesContextProps>(
    () => ({
      ...cookiePreferences,
      isBannerOpen,
      isPreferencesOpen,
      updateConsentCookie,
      setUpdateConsentCookie,
      setIsPreferencesOpen,
      setIsBannerOpen,
      handleOnAcceptAll,
      handleOnAcceptChoice,
      handleOnRejectAll,
      handlePreferenceClick,
      handleManagePreferenceClick,
      setShouldInitialize,
    }),
    [
      isBannerOpen,
      cookiePreferences,
      isPreferencesOpen,
      updateConsentCookie,
      setUpdateConsentCookie,
      setShouldInitialize,
      setIsBannerOpen,
      setIsPreferencesOpen,
      handleOnAcceptAll,
      handleOnAcceptChoice,
      handleOnRejectAll,
      handlePreferenceClick,
      handleManagePreferenceClick,
    ]
  );

  useEffect(() => {
    if (shouldInitialize) {
      setIsBannerOpen(false);
      initialiseGDPR();
    }

    /*
     * every time cookiePreferencesDispatch gets called to update the state,
     * we should clear the 'cookie' slate, update the local storage with a useEffect
     */
    if (updateCookieConsentPreferences) {
      setUpdateCookieConsentPreferences(false);
      setCookieConsentPreferences(cookiePreferences);
    }
  }, [
    cookiePreferences,
    updateCookieConsentPreferences,
    shouldInitialize,
    isBannerOpen,
    gdprPreferencesAccepted,
    setCookieConsentPreferences,
    initializeGoogleTagManager,
    removeAllCookies,
    initialiseGDPR,
  ]);

  return (
    <CookiePreferencesContext.Provider value={cookiePreferencesValue}>
      {children}
    </CookiePreferencesContext.Provider>
  );
};

export const useCookiePreferences = () => useContext(CookiePreferencesContext);
